[data-tooltip] {
  position: relative;
  cursor: help;
}

[data-tooltip]::after {
  color: black;
  position: absolute;
  pointer-events: none;
  content: attr(data-tooltip);
  left: 20px;
  bottom: calc(100% + 50px);
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
  background-color: white;
  z-index: 10;
  padding: 3px 20px;
  width: 0;
  /* transform: translateY(-20px); */
  transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  display: none;
}

[data-tooltip]:hover::after {
  /* opacity: 1; */
  /* transform: translateY(0); */
  transition-duration: 300ms;
  display: block;

  width: unset;
}
