.App .slide .contentM {
  margin: auto;
  padding: 2rem 0.5rem 6rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 60;
  width: 90%;
  overflow-x: hidden;
  position: relative;
}

.App .slide .contentM .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App .slide .contentM .top h2 {
  font-weight: 100;
}

.App .slide .contentM .bot {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App .slide .contentM .bot .button {
  margin: 1rem;
  padding: 0.8rem 3rem;
  background: rgba(255, 255, 255, 0.103);
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  font-size: 1.5rem;
  transition: 0.1s cubic-bezier(0.42, 0, 1, 1.74);
}

.App .slide .contentM .bot .button:hover {
  transform: scale(1.2);
}

/* low height */
/* @media screen and (max-height: 640px) {
  .App .slide .contentM {
    top: 60px;
    left: 0;
    position: fixed;
  }
  .App .slide .contentM .top h1 {
    font-size: 1.7rem;
  }
  .App .slide .contentM .top h2 {
    font-size: 1.5rem;
  }
  .App .slide .contentM .top img {
    width: 100px;
  }
  .App .slide .contentM .bot .button {
    margin: 0.1rem;
    padding: 0.2rem 3rem;
  }
} */

/* @media screen and (max-height: 320px) {
  .App .slide .contentM {
    top: 50px;
  }
  .App .slide .contentM .top h1 {
    font-size: 1.5rem;
  }
  .App .slide .contentM .top h2 {
    font-size: 1.3rem;
  }
} */

/* mobile */
@media screen and (max-width: 600px) {
  .App .slide .contentM {
    padding: 0;
  }
  .App .slide .contentM .top {
    margin-top: 2rem;
  }

  .App .slide .contentM .top h1 {
    font-size: 1.6rem;
  }
  .App .slide .contentM .top h2 {
    font-size: 1.2rem;
  }
}

/* iphone 5 */
@media screen and (max-width: 374px) {
  .App .slide .contentM .top {
    margin-top: 4rem;
  }
  .App .slide .contentM .top h1 {
    font-size: 1.4rem;
  }
  .App .slide .contentM .top h2 {
    font-size: 1rem;
  }
}
